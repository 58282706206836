import { USERCENTRICS_TCF_VENDOR_ID } from './UsersentricsConfig';

export const isEmetriqAllowed = () => {
    try {
        return window.UC_UI.getTCFVendors().find((data: any) => {
            return data.id === USERCENTRICS_TCF_VENDOR_ID.EMETRIQ;
        }).consent || false;
    } catch (e) {
        return false;
    }
};
