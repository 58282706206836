import { UsercentricsConfig } from './UsersentricsConfig';
import { dispatchChangeEvent, dispatchReadyEvent, isConsentSuppressed } from '../ConsentLoader';

export const USERCENTRICS_EVENT_INITIALIZED = 'UC_UI_INITIALIZED'
export const USERCENTRICS_EVENT_CHANGE = 'UC_UI_EVENT' // The name of the event is configured in the Usercentrics Admin interface

const isCmpSuppressed = () => {
    if (isConsentSuppressed()) {
        return true;
    }
    const {excludedPaths} = UsercentricsConfig;
    const currentPath = window.location.pathname.replace(/\/+$/, '');
    return excludedPaths.includes(currentPath);
};

export const loadUsercentricsCmp = (settingsId: string) => {
    if (isCmpSuppressed()) {
        window.UC_UI_SUPPRESS_CMP_DISPLAY = true;
    }

    window.addEventListener(USERCENTRICS_EVENT_INITIALIZED, function(e) {
        dispatchReadyEvent()
    });

    window.addEventListener(USERCENTRICS_EVENT_CHANGE, function(e) {
        dispatchChangeEvent(e)
    });

    var script = document.createElement('script');
    script.id = 'usercentrics-cmp';
    script.src = `https://app.usercentrics.eu/browser-ui/${UsercentricsConfig.version}/loader.js`;
    script.dataset.settingsId = settingsId;
    script.dataset.tcfEnabled = 'true';
    document.head.appendChild(script);
};
