import { ConsentVariant } from './ConsentVariant';
import { getTestContext, isTestContext, TestContext } from './TestContext';

export type LoaderSettings = {
    consentVariant: ConsentVariant;
    isProduction: boolean;
};

const isProduction = window.location.hostname.indexOf('.congstar.de') !== -1;

const defaultLoaderSettings: LoaderSettings = {
    consentVariant: 'USERCENTRICS',
    isProduction: isProduction
};

export const getLoaderSettings = (): LoaderSettings => {
    try {
        if (isTestContext()) {
            const testContext = getTestContext();
            const loaderSettings = getLoaderSettingsForTestContext(testContext);
            console.info(`[consent-loader]: Testmodus "${testContext}" aktiv`);
            return loaderSettings;
        }
    } catch (e) {
        // do nothing
    }
    return defaultLoaderSettings;
};

const getLoaderSettingsForTestContext = (testContext: TestContext): LoaderSettings => {
    switch (testContext) {
        case 'cmp':
            return {
                consentVariant: 'USERCENTRICS',
                isProduction: true
            };
        case 'cmp.test': {
            return {
                consentVariant: 'USERCENTRICS',
                isProduction: false
            };
        }
        default:
            throw new Error('Not supported value for test context');
    }
};
