import { ConsentVariant } from './ConsentVariant';
import * as UsercentricsCmp from './usercentrics';
import { showUsercentricsCmp } from './usercentrics';

export const showConsentSettings = (consentVariant: ConsentVariant) => {
    switch (consentVariant) {
        case 'USERCENTRICS':
            return showUsercentricsCmp();
        default:
            throw new Error(`Consent variant ${consentVariant} not supported.`);
    }
};

export const isEmetriqAllowed = (consentVariant: ConsentVariant) => {
    switch (consentVariant) {
        case 'USERCENTRICS':
            return UsercentricsCmp.isEmetriqAllowed();
        default:
            throw new Error(`Consent variant ${consentVariant} not supported.`);
    }
};
