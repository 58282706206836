import { getCookie, hasCookie } from './utils/cookie';

const TEST_CONTEXT_COOKIE_NAME = 'CONGSTAR_CONSENT_TEST';
export type TestContext = 'cmp' | 'cmp.test'

export const isTestContext = () => {
    return hasCookie(TEST_CONTEXT_COOKIE_NAME);
};

export const getTestContext = (): TestContext => {
    return getCookie(TEST_CONTEXT_COOKIE_NAME) as TestContext;
};
